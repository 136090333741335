@font-face 
{
    font-family: 'OpenSans-semibold';
    src: url('../fonts/OpenSans-semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face 
{
    font-family: 'OpenSans-bold';
    src: url('../fonts/OpenSans-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face 
{
    font-family: 'OpenSans-regular';
    src: url('../fonts/OpenSans-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/OpenSans-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/OpenSans-regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/OpenSans-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/OpenSans-regular.svg#svgFontName') format('svg'); /* Legacy iOS */
       font-weight: normal;
       font-style: normal;
}
body{
    font-family: 'OpenSans-regular';
}
@media (min-width:200px) and (max-width: 767px){
.col-custom-sm{
  width:200px;
}
.txt-style{
  font-size:14px!important;
}
.btn-primary-custom{
  padding:10px 60px!important;
}
.prev-button{
  width:96%;
}
.btn-primary-custom{
  width:96%;
}
.outline-btn
{
  width:96%;
}
.col-md-1, .col-md-2,.col-md-3, .col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12{
  width:100%;
}
.d-md{
  display: none!important;
}
.d-sm{
  display: block!important;
}
.col-sm-7{
  flex: 0 0 auto;
  width: 58.3333333333%;
}
#progressbar li:before {
    
  margin:0px 33px!important;
}
}
@media only screen and  (min-width:768px) {
  .d-md{
    display:block!important;
  }
  .d-sm{
    display: none!important;
  }
}

@media (min-width:768px) and (max-width: 999px)
{
  #progressbar li:before {
    
    margin:0px 38px!important;
  }
  #progressbar li:after {
    content: '';
    width: 100%;
    height: 1.5px;
    border: 2px solid #E6E6E6;
    position: absolute;
    left: 56px;
    top: 13px;
    z-index: -1;
  }
}
@media (min-width:1000px) and (max-width: 1024px) {
    
    .col-mdl-1 {
        flex: 0 0 auto;
        width: 8.3333333333%;
      }
    
      .col-mdl-2 {
        flex: 0 0 auto;
        width: 16.6666666667%;
      }
    
      .col-mdl-3 {
        flex: 0 0 auto;
        width: 25%;
      }
    
      .col-mdl-4 {
        flex: 0 0 auto;
        width: 33.3333333333%;
      }
    
      .col-mdl-5 {
        flex: 0 0 auto;
        width: 41.6666666667%;
      }
    
      .col-mdl-6 {
        flex: 0 0 auto;
        width: 50%;
      }
    
      .col-mdl-7 {
        flex: 0 0 auto;
        width: 58.3333333333%;
      }
    
      .col-mdl-8 {
        flex: 0 0 auto;
        width: 66.6666666667%;
      }
    
      .col-mdl-9 {
        flex: 0 0 auto;
        width: 75%;
      }
    
      .col-mdl-10 {
        flex: 0 0 auto;
        width: 83.3333333333%;
      }
    
      .col-mdl-11 {
        flex: 0 0 auto;
        width: 91.6666666667%;
      }
    
      .col-mdl-12 {
        flex: 0 0 auto;
        width: 100%;
      }
  }
.nav-bg-color{
    background-color: #089AD7;
}
.txt-style-bold{
    font-family: 'OpenSans-bold';
    color:white;
    font-size: 18px;
}
.logo-position{
    margin-bottom:-20px;
}
.txt-style-semibold{
    font-family:'OpenSans-semibold';
    color:white;
    font-size: 13px;
}
.mt-custom{
    margin-top:13px;
}
.blue-title-txt{
    color:#089AD7 !important;
    font-family:'OpenSans-bold' !important;
    font-size: 23px !important;
}
.sub-heading-txt{
    color:#20252E;
    font-family: 'OpenSans-bold';
    font-size: 18px;
}
.txt-style{
  font-family:'OpenSans-semibold';
  font-size:15px;
}
.points{
  font-size:15px;
}
.bullet-points{
  display: inline-block;
  width:8px;
  height:8px;
  background-color: #089AD7;
  border-radius: 50%;
  margin-right:5px;
}
.gray-timertxt{
  font-family: 'OpenSans-semibold';
  color:#4F4F4F;
  font-size:13px;
}
  .btn-primary-custom{
    background-color: #089AD7 !important;
    color:white;
    font-family: 'OpenSans-bold';
    font-size:16px;
    border-radius:0px;
    padding:10px 70px;
  }
  .form-label{
    font-family: 'OpenSans-semibold';
    font-size: 16px;
  }
  .mandatory:after{
    content:"*";
    color:#BF0000;
  }
  .verify-icon{
    background-image: url(../images/verfied.svg);
    background-repeat: no-repeat;
    padding-right:calc(1.5em + .75rem);
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }
  .calendar{
    background-image: url(../images/calendar-icon.svg)!important;
  }
  .form-control,.form-select{
    border:1px solid #089AD7;
    font-family: 'OpenSans-semibold';
    border-radius: 0px;
  }
  .info-container {
    cursor: pointer;
    margin-left: 2px;
    position: relative;
  }
  .info-content {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 4px #ccc;
    padding: 10px;
    min-width: 200px;
    left:100%;
    top: -11px;
    display: none;
}
.info-container:hover .info-content {
  display: block;
}
.info-img{
  width:14px;
}
.prev-button{
  color:#089AD7;
  font-family: 'OpenSans-semibold';
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
}
.prev-button:hover{
  color:#089AD7;
}
.txtbox-height{
  height:57px;
}
.w-23{
  width:16%;
  margin:2%;
}
.outline-btn{
  border:2px solid #0F99D6;
  color:#0F99D6;
  background-color: white;
  font-family: 'OpenSans-bold';
  font-size: 16px;
  border-radius:0px;
}
.popup-txt-style{
  font-family: 'OpenSans-semibold';
  font-size:18px;
}
.btn-primary-custom:hover{
  background-color: #0886bd !important;
  color:white;
}
.outline-btn:hover{
  border:2px solid #0F99D6;
  color:#0F99D6;
  background-color: #ddf5ff;
}
.color-black{
  color:black;
}
.info-card{
  padding:15px 20px;
  box-shadow: 0 0 4px #e4e4e4;
  border-radius: 5px;
  
}
.card-heading-txt{
  font-family:'OpenSans-bold';
  color:#606060;
  font-size:14px;
}
.card-txt-style{
  font-family: 'OpenSans-semibold';
  font-size:14px;
  color:black;
}
.edit-icon{
  padding:10px 60px;
}
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  padding-left: unset;
  text-align: center;
}
#progressbar .active {
  color: #0F78D4;
}
#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
  font-family: '';
}
#progressbar li.active .wizardtxt {
  color: #4D4D4D;
  font-family:'OpenSans-semibold';
  font-size: 12px;
  color:#707070;
  padding-top:10px;
  display: inline-block;
}
#progressbar li.active .accordion-steps {
  color: white;
}
#progressbar li.completion-status:before {
  
  border: 1.5px solid #089AD7!important;
  background-color: #089AD7!important;
  background-image: url(../images/completed-status.svg)!important;
  width: 20px!important;
  height: 20px!important;
  background-position: 6px 8px!important;
  background-size: 13px!important;
  background-repeat: no-repeat!important;
}
#progressbar li.completion-status:after {
  border: 2px solid #4DC182;
}
#progressbar li.active:before {
  background: #fff;
  border: 2px solid #089AD7;
  background-color: white;
}
#progressbar li.active:after {
  border: 1px solid #089AD7;
}
#progressbar li:after {
  content: '';
  width: 100%;
  height: 1.5px;
  border: 2px solid #E6E6E6;
  position: absolute;
  left: 56px;
  top: 13px;
  z-index: -1;
}
#progressbar li:before {
  width: 15px;
  height: 15px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  border-radius: 50%;
  padding: 13px;
  content: '';
  margin:0px 48px;
}
#progressbar li:last-child:after{
  border:none;
}
.sub-form-heading{
  font-family: 'OpenSans-bold';
  font-size:16px;
  color:#404040;
}
.input-group-text{
  background-color: #DFF0F7;
  border-radius: 0px;
  border:1px solid #089AD7;
}
.border-r-0{
  border-left:none;
}
.form-check-label{
  font-family: 'OpenSans-semibold';
  font-size: 14px;
}
.information-container{
  border:1px solid #98CFEC;
  background-color: #F5FAFD;
}
.label-header-style{
  font-family: 'OpenSans-bold';
  font-size: 16px;
}
.label-style-txt{
  font-family: 'OpenSans-semibold';
  font-size: 15px ;
}
.font-regular{
  font-family: 'OpenSans-regular'!important;
}
.banner-bg{
  background-image: url(../images/bg-img.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.font-18{
  font-size: 18px;
}
.sub-heading-txtstyle{
  font-family: 'OpenSans-semibold';
  font-size: 16px;
}


/* code added to remove border */
.border-r  input[type="text"] 
{
  border: 0px solid;
}
.border-r input[type="text"] :focus
{
  border: 0px solid;
}
.react-datepicker-wrapper
{
  width:100%;
}
.no-repeat
{
  background-repeat: no-repeat;
  background-origin:content-box;
  background-position: right;
}

.show{
  display: block;
}
.OtpValidation
{
  font-size: 15px;
  color: red;
  text-align: center;
}
.bg-rem
{
  background:none;
  border: none;
}

.form-controls input[type="text"]{
  border:1px solid #089AD7;
  font-family: 'OpenSans-semibold';
  border-radius: 0px;
}
.txtbox-heights input[type="text"]{
  height:57px;
}
.ws-23 input[type="text"]{
  width:92%  !important;
  margin:4%  !important;
}
.float-starts input[type="text"]  {
  float: left !important;
}
.form-controls input[type="text"] :focus
{
  border: 0px solid blue!important;
  /* color:red  !important;
  border-color: red !important; */
}

.ca-approval-div
{
  width: 44% !important;
  margin: 0px auto;
}
.ca-approval-li
{
  width: 50% !important;
}


/* .borders-rs  input[type="text"] 
{
  border: 0px solid red;
}
.borders-rs input[type="text"] :focus
{
  border: 0px solid red;
} */
input.form-control.calendar.daypicker[readonly] {
  background-color: white !important;
  width: 100 !important;
  }
  .flatpickr-wrapper {
    width: 100% !important;
    }

    .p-js{text-align: justify;}

 .fl {
     float:left !important;
}
.otp-center{text-align: center;}
